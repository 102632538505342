import React from 'react';
import PropTypes from 'prop-types';
import HandledImage from '../../HandledImage/HandledImage';
import './DeckPage.scss';

const DeckPage = ({
    id,
    imageSrc,
    onNextPageChange,
    pageNumber
}) => (
    <button
        type="button"
        className="deckpage__container"
        id={pageNumber}
        onClick={onNextPageChange}
    >
        <HandledImage id={id} alt={imageSrc} src={imageSrc} retry />
    </button>
);

DeckPage.propTypes = {
    id: PropTypes.string,
    imageSrc: PropTypes.string,
    onNextPageChange: PropTypes.func.isRequired,
    pageNumber: PropTypes.number
};

DeckPage.defaultProps = {
    id: '',
    imageSrc: '',
    pageNumber: 0
};

export default DeckPage;
